/*
 * @Author: limit
 * @Date: 2023-07-17 17:28:48
 * @LastEditTime: 2024-12-23 18:29:29
 * @LastEditors: lianyj lianyj@glodon.com
 * @FilePath: \portal-web\src\router\index.ts
 * @Description: 由limit创建！
 */

import {
  type RouteRecordRaw,
  type Router,
  createRouter,
  createWebHistory,
} from 'vue-router';

export const HOMEPAGE = '/'; // 首页
export const TENDER_NOTICE = '/portal/tender-notice'; // 招标公告
export const RESULT_PUBLISH = '/portal/result-publish'; // 结果发布
export const BID_PUBLICITY = '/portal/bid-publicity'; // 中标公示
export const NEWS_INFORMATION = '/portal/news-information'; // 新闻资讯
export const POLICIES_REGULATION = '/portal/policies-regulation'; // 政策法规
export const INDUSTRY_NEWS = '/portal/industry-news'; // 行业动态
export const ANNOUNCEMENT = '/portal/announcement'; // 通知公告
export const FILE_DOWNLOAD = '/portal/file-download'; // 资料下载
export const ABOUT_US = '/portal/about-us'; // 关于我们
export const HELP_CENTER = '/portal/help-center'; // 帮助中心
export const GLOBAL_SEARCH = '/portal/search'; // 全局搜索
export const EDIT_TEMPLATE = '/portal/homePage';

const routes: RouteRecordRaw[] = [
  {
    path: HOMEPAGE,
    name: 'App',
    component: () => import('@/views/dragPage/index'), // done
  },
  {
    path: TENDER_NOTICE,
    name: 'TenderNotice',
    component: () => import('@/views/tenderNotice/index'),
    meta: {
      title: '招标公告',
      code: 'notice',
    },
  },
  {
    path: TENDER_NOTICE + '/:docId',
    name: 'TenderNoticeDetail',
    component: () => import('@/views/tenderNotice/detail'),
    meta: {
      parent: TENDER_NOTICE,
      title: '招标详情',
      code: 'notice',
    },
  },
  {
    path: BID_PUBLICITY,
    name: 'BidPublicity',
    component: () => import('@/views/bidPublicity/index'),
    meta: {
      title: '中标公示',
      code: 'pub',
    },
  },
  {
    path: RESULT_PUBLISH,
    name: 'ResultPublish',
    component: () => import('@/views/bidPublicity/index'),
    meta: {
      title: '招标结果',
      code: 'res',
    },
  },
  {
    path: BID_PUBLICITY + '/:docId',
    name: 'BidPublicityDetail',
    component: () => import('@/views/bidPublicity/detail'),
    meta: {
      parent: BID_PUBLICITY,
      title: '中标详情',
      code: 'pub',
    },
  },
  {
    path: RESULT_PUBLISH + '/:docId',
    name: 'ResultPublishDetail',
    component: () => import('@/views/bidPublicity/detail'),
    meta: {
      parent: RESULT_PUBLISH,
      title: '结果详情',
      code: 'res',
    },
  },
  {
    path: NEWS_INFORMATION,
    name: 'NewsInformation',
    component: () => import('@/views/newsInformation/index'),
    meta: {
      title: '新闻资讯',
      code: 'news',
    },
  },
  {
    path: NEWS_INFORMATION + '/:docId',
    name: 'NewsInformationDetail',
    component: () => import('@/views/newsInformation/detail'),
    meta: {
      parent: NEWS_INFORMATION,
      title: '详情页',
      code: 'news',
    },
  },
  {
    path: POLICIES_REGULATION,
    name: 'PoliciesRegulation',
    component: () => import('@/views/newsInformation/index'),
    meta: {
      title: '政策法规',
      code: 'law',
    },
  },
  {
    path: POLICIES_REGULATION + '/:docId',
    name: 'PoliciesRegulationDetail',
    component: () => import('@/views/newsInformation/detail'),
    meta: {
      parent: POLICIES_REGULATION,
      title: '政策法规详情',
      code: 'law',
    },
  },
  {
    path: INDUSTRY_NEWS,
    name: 'IndustryNews',
    component: () => import('@/views/newsInformation/index'),
    meta: {
      title: '行业动态',
      code: 'dynamic',
    },
  },
  {
    path: INDUSTRY_NEWS + '/:docId',
    name: 'IndustryNewsDetail',
    component: () => import('@/views/newsInformation/detail'),
    meta: {
      parent: INDUSTRY_NEWS,
      title: '行业动态详情',
      code: 'dynamic',
    },
  },
  {
    path: ANNOUNCEMENT,
    name: 'Announcement',
    component: () => import('@/views/newsInformation/index'),
    meta: {
      title: '通知公告',
      code: 'inform',
    },
  },
  {
    path: ANNOUNCEMENT + '/:docId',
    name: 'announcementDetail',
    component: () => import('@/views/newsInformation/detail'),
    meta: {
      parent: ANNOUNCEMENT,
      title: '通知公告详情',
      code: 'inform',
    },
  },
  {
    path: FILE_DOWNLOAD,
    name: 'FileDownload',
    component: () => import('@/views/fileDownload/index'),
    meta: {
      title: '资料下载',
      code: 'download',
    },
  },
  {
    path: HELP_CENTER, // done
    name: HELP_CENTER,
    component: () => import('@/views/helpCenter/index'),
    meta: {
      title: '帮助中心',
    },
  },
  {
    path: ABOUT_US, // done
    name: 'AboutUs',
    component: () => import('@/views/aboutUs/index'),
    meta: {
      title: '关于我们',
    },
  },
  {
    path: GLOBAL_SEARCH,
    name: 'GlobalSearch',
    component: () => import('@/views/globalSearch/index'),
    meta: {
      title: '全局搜索',
    },
  },
  {
    path: EDIT_TEMPLATE,
    name: 'EditTemplate',
    component: () => import('@/views/dragPage/index'),
    meta: {
      title: '编辑模板',
    },
  },
];

const router: Router = createRouter({
  routes: routes,
  scrollBehavior: () => ({
    top: 0,
  }),
  history: createWebHistory(),
});

export default router;
