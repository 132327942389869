/*
 * @Author: lianyj lianyj@glodon.com
 * @Date: 2024-12-06 16:39:36
 * @LastEditors: lianyj lianyj@glodon.com
 * @LastEditTime: 2024-12-25 15:32:39
 * @FilePath: \portal-web\src\router\guard.ts
 * @Description: 路由守卫
 */
import router from '@/router';
import NProgress from 'nprogress';
import 'nprogress/css/nprogress.css';

NProgress.configure({ showSpinner: false });

// 路由守卫
router.beforeEach(async (to, from, next) => {
  NProgress.start();

  // 从当前页面的 URL 中获取 templateId
  const urlParams = new URLSearchParams(window.location.search);
  const templateId = urlParams.get('templateId');

  // 检查是否已经有 templateId 参数
  if (templateId && !to.query.templateId) {
    // 克隆当前的 query 对象
    const query = { ...to.query, templateId };
    // 使用 next 方法重定向到带有 templateId 参数的目标路由
    next({ ...to, query });
  } else {
    // 如果已经有 templateId 参数，直接继续
    next();
  }

  // document.title = to.meta.title as string || '企业门户';
});

// 路由加载后；
router.afterEach(() => {
  NProgress.done();
});
